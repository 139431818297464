export const environment = {
    production: true,
    appName: 'Blumepress Pressroom',
    url: `https://api-dev.blumepress.com/`,
    email: `pressroom-dev`,
    main_url: `https://app-dev.blumepress.com/`,
    emailDomain: `@blumepress.com`,
    s3Url: 'https://pressroom-dev.s3.eu-west-1.amazonaws.com',
    livesiteUrl: `https://pressroom-dev.blumepress.com/`,
};
